// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-docs-js": () => import("/opt/build/repo/website/src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-list-page-js": () => import("/opt/build/repo/website/src/templates/listPage.js" /* webpackChunkName: "component---src-templates-list-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("/opt/build/repo/website/src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-documentation-js": () => import("/opt/build/repo/website/src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

