module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KeystoneJS Docs","short_name":"Docs","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#ffffff","background_color":"#ffffff","display":"standalone"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/website/src/components/markdown/mdx-renderer.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"/opt/build/repo/website/plugins/gatsby-remark-fix-links/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":848}},{"resolve":"gatsby-remark-copy-linked-files"}],"rehypePlugins":[[null,{"ignoreMissing":true}]]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43970386-3","head":true},
    },{
      plugin: require('/opt/build/repo/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
